import { Box, Container, Flex, Stack } from '@chakra-ui/react'

import ConnectMetamaskButtonNoMint from './ConnectMetamaskButtonNoMint'
interface MainLayoutProps {
  children: JSX.Element
}

const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <>
        {children}
    </>
  )
}

export default MainLayout
