import {
  Box,
  chakra,
  Container,
  Stack,
  Text,
  Image,
  Flex,
  VStack,
  Button,
  Heading,
  SimpleGrid,
  StackDivider,
  useColorModeValue,
  VisuallyHidden,
  List,
  ListItem,
  CircularProgress,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';

import { FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import { MdLocalShipping } from 'react-icons/md';
import { useMetamask, useAddress, useNFTDrop, useNetworkMismatch } from '@thirdweb-dev/react'
import { MdAccountBalanceWallet } from 'react-icons/md'
import { NFT_COLLECTION_MUMBAI_CONTRACT } from '../config/contracts'
import React, { useState, useContext, useLayoutEffect, useEffect } from 'react';
import { NFTMetadataOwner,NFTMetadata } from '@thirdweb-dev/sdk'
import { AppContext } from '../App'
import Soldout from './Soldout'
import rawdata from '../data/merged.json';
let itemid=(Math.floor(Math.random() * rawdata.length));



export default function Simple() {
  const address = useAddress();
  const networkMatch = useNetworkMismatch();
  const connectWithMetamask = useMetamask();
  const nft = useNFTDrop(NFT_COLLECTION_MUMBAI_CONTRACT)
  const [steps, setSteps] = useState('Step 2: Claim the NFT');
  const [isLoading, setIsLoading] = useState(false)
  const { nftList, setNftList } = useContext(AppContext)
  const [nftleft, setNftleft] = useState(1);

  const inventory=1;
  const ProgressCircleTemplate = () => (
    <Flex justifyContent="center" alignItems="center">
      <CircularProgress isIndeterminate color="purple.500" boxSize={200} />
    </Flex>
  )

  //
  // useEffect(() => {
  //   let nftItem;
  //   nftItem=rawdata[0];
  //   console.log(nftItem)
  //   setIsLoading(false);
  //
  //     // nft?.totalUnclaimedSupply().then((nftsRes) => {
  //     //   setIsLoading(true)
  //     //   setNftleft(nftsRes.toNumber());
  //     // }).then(()=>{
  //     //   setIsLoading(false);});
  //
  //       // nft?.totalSupply().then(result=>{
  //       //   // var countNum=(Number(result)>100)?Number(result):100;
  //       //   // settotalSupply(countNum);
  //       //   const randomElement = Math.floor(Math.random() * Number(result));
  //       //   nft?.getAll({start:randomElement, count:1}).then((nftsRes: NFTMetadataOwner[]) => {
  //       //     nftsRes.sort(function() { return 0.5 - Math.random() });
  //       //     setNftItem(nftsRes);
  //       //     setIsLoading(false);
  //       //   })
  //       // })
  // }, []);

  const mintdHandle = () => {
    if (!address) {
      return
    }
    setSteps('Process started, please wait for Metamask to obtain gas fees information.');
    setIsLoading(true);
    nft
      ?.claimTo(address, 1)
      .then(() => {
        setSteps('Transaction successful, waiting for data update.');
        return nft?.getAllClaimed()
      })
      .then((nftsRes: NFTMetadataOwner[]) => {
        setSteps('Updating claimed NFT data.');
      })
      .finally(() => {
        setSteps('Your NFT is successfully claimed, updating your NFT List');
        nft?.getOwned(address).then((nftsRes: NFTMetadataOwner[]) => {
          setNftList(nftsRes);
        }).then(()=>{
          setIsLoading(false);
          setSteps('Process completed.');
        })
        // onClose();
      })
      .catch((error) => {
        // Your error is here!
        console.log(error.message);
        setSteps(error.message);
      });
  }

  return (
    <Container maxW={'7xl'} padding={10}>
      <SimpleGrid
        columns={{ base: 1, lg: 2 }}
        spacing={{ base: 8, md: 8 }}
        py={{ base: 18, md: 10 }}>
        <Flex m="0 auto">
        {rawdata[itemid].image!=undefined ? (
          <Image
            rounded={'md'}
            alt={'product image'}
            src={
              rawdata[itemid].image?(rawdata[itemid].image):'bg.jpg'
            }
            fit={'cover'}
            align={'center'}
            w={{ base: '100%', sm: '250px', lg: '600px' }}
            h={{ base: '100%', sm: '250px', lg: '600px' }}
            className={"product-image"}
          />):(
            <ProgressCircleTemplate />
          )
        }
        </Flex>
        <Stack spacing={{ base: 6, md: 10 }}>
          <Box as={'header'}>
            <Heading
              lineHeight={1.2}
              fontWeight={600}
              fontSize={{ base: '2xl', sm: '3xl', lg: '4xl' }}>
              Generative Art NFT with Randomness
            </Heading>
          </Box>

          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={'column'}
            divider={
              <StackDivider
                borderColor={useColorModeValue('gray.200', 'gray.600')}
              />
            }>
            <Text
              color={useColorModeValue('white.900', 'white.400')}
              fontWeight={300}
              fontSize={'2xl'}
              mt={4} >
              A Free NFT Generated With Randomness
            </Text>
            <Text fontSize={'lg'} textAlign="justify" >
              This is a collection of generative art created with randomness. The project aims to explore different algorithm generative art projects and add more randomness to the algorithm in order to create more unexpected patterns and shapes. The created artworks are free to claim as an NFT for everyone. Simply login Metamask and claim the NFT, and you can start selling it on marketplaces like OpenSea, Rarible, Mintable etc. 2% of royalties will be sent back to the project's address after the artwork is sold.
            </Text>
            <Box id="howtoclaim">
              <Text
                color={useColorModeValue('purple.500', 'purple.300')}
                textTransform={'uppercase'}
                fontWeight={300}
                fontSize={'2xl'}
                mb={4} >
                How To Claim It?
              </Text>
              <Text fontSize={'lg'} textAlign="justify" mb={4}>
                Connect Metamask, Switch to Polygon Network and Claim, The Next Available Token Will Be Claimed To Your Wallet.
              </Text>
              <List spacing={2}>
                <ListItem>
                {
                address ? (
                  <Text as={'span'} fontWeight={'bold'} id="claimingtext">
                    {steps}
                  </Text>
                ) : nftleft < inventory ? (<Soldout/>) :
                (
                  <Box>
                    <Flex mb={5}>
                      <Text as={'span'} fontWeight={'bold'}>
                        Step 1:
                        Connect Metamask
                      </Text>
                    </Flex>
                    <Flex>
                      <Button
                        rounded={'none'}
                        colorScheme="purple"
                        w={'full'}
                        mt={0}
                        size={'sm'}
                        py={'7'}
                        textTransform={'uppercase'}
                        _hover={{
                          transform: 'translateY(2px)',
                          boxShadow: 'lg',
                        }}
                        onClick={connectWithMetamask}
                        leftIcon={<MdAccountBalanceWallet />}
                      >
                        Connect Metamask
                      </Button>
                    </Flex>
                  </Box>
                )}
                </ListItem>
              </List>
            </Box>
          </Stack>
          {
            nftleft < inventory ? <></> :
            address ?
            (
            isLoading ? (
              <ProgressCircleTemplate />
            ) :  networkMatch ? (
              <Alert status='error'>
                <AlertIcon />
                <AlertTitle>Network Mismatch</AlertTitle>
                <AlertDescription>Please Switch To Polygon Network To Claim The NFT.</AlertDescription>
              </Alert>
            ) :(
              <Button
                rounded={'none'}
                w={'full'}
                mt={0}
                size={'sm'}
                py={'7'}
                textTransform={'uppercase'}
                _hover={{
                  transform: 'translateY(2px)',
                  boxShadow: 'lg',
                }}
                onClick={mintdHandle}
                className="claim-button"
                colorScheme="purple"
                >
                Claim A Random Generative Art NFT
              </Button>
            )

          ) : (
            <Flex>
              <Text as={'span'} fontWeight={'bold'}>
                {' '}
              </Text>
            </Flex>
          )}

          { nftleft < inventory ?<></>  :
            address ? (
            <Stack direction="row" alignItems="center" justifyContent={'center'}>
              <Text>* After the claiming process starts, you will need to pay the gas fees for claiming the NFT. Please wait patiently for it to confirm. Once the transaction is fully confirmed, you can check the NFT in the your account through <a href="https://polygonscan.com/" target="_blank">PolyScan</a> or the market place platform like OpenSea.</Text>
            </Stack>
          ) : (
            <Flex>
              <Text as={'span'} fontWeight={'bold'}>
                {' '}
              </Text>
            </Flex>
          )}

        </Stack>
      </SimpleGrid>
    </Container>
  );
}
