import { Flex, Text } from '@chakra-ui/react'
import { useAddress } from '@thirdweb-dev/react'

import Logo from './Logo'
import {NFTAll,NFTSlideshow} from './NFTAll'
import Product from './Product'
import {Footer} from './Features'
const Home = () => {
  const address = useAddress()

  return (
    <>
      <Flex>
          <Logo />
      </Flex>
      <Flex id="description">
          <Product />
      </Flex>
      {address ? (
        <>
        <Flex>
          <NFTSlideshow />
        </Flex>
        </>
      ) : (
        <Flex justifyContent="center" alignItems="center" flexDir={'column'}>
          <Text px={8}>Connect Metamask wallet to start minting your NFTs</Text>
        </Flex>
      )}
      <Flex id="recent">
        <NFTAll />
      </Flex>
      <Flex>
        <Footer />
      </Flex>
    </>
  )
}

export default Home
