import {
  Box,
  Text,
  Image,
  Flex,
  CircularProgress,
  Center,
  Stack,
  chakra,
  GridItem,
  VStack,
  Grid,
  Container, SimpleGrid
} from '@chakra-ui/react'
import { useNFTDrop, useAddress } from '@thirdweb-dev/react'
import { NFTMetadata, NFTMetadataOwner } from '@thirdweb-dev/sdk'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../App'
import { NFT_COLLECTION_MUMBAI_CONTRACT } from '../config/contracts'
import rawdata from '../data/merged.json';

export const NFTAll = () => {
  const nftCollection = useNFTDrop(NFT_COLLECTION_MUMBAI_CONTRACT)
  const { nftListAll, setNftListAll } = useContext(AppContext)
  const [isLoadingAll, setIsLoadingAll] = useState(true)
  // const [totalSupply, settotalSupply] = useState(0)

  useEffect(() => {
    // let imagesdata = JSON.parse(rawdata);
    setNftListAll(rawdata);
    setIsLoadingAll(false);

    // if (!nftListAll.length) {
    //   nftCollection?.totalSupply().then(result=>{
    //     // var countNum=(Number(result)>100)?Number(result):100;
    //     // settotalSupply(countNum);
    //     nftCollection?.getAllUnclaimed({count:250}).then((nftsRes: NFTMetadata[]) => {
    //       nftsRes.sort(function() { return 0.5 - Math.random() });
    //       setNftListAll(nftsRes);
    //       setIsLoadingAll(false);
    //     })
    //   })
    // }
  }, []);
  const ProgressCircleTemplate = () => (
    <Flex justifyContent="center" alignItems="center">
      <CircularProgress isIndeterminate color="purple.500" boxSize={200} />
    </Flex>
  )

  const NoNFTTemplate = () => (
    <Flex justifyContent="center" alignItems="center">
      <Text px={8}>You don't have NFT yet, start uploading your first NFT</Text>
    </Flex>
  )

  const NFTListTemplate = () => {
    return (
      <>
      <Box as={Container} maxW={["auto", "auto", "auto", "full",]} mt={5} p={2} colorScheme="white" >
        <Flex>
          <chakra.h2 fontSize="3xl" fontWeight="700" px={2} pb={6}>Recently Released Unclaimed NFT</chakra.h2>
        </Flex>
        <SimpleGrid
        columns={{ base: 1, lg: 1 }}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 10, md: 6 }}
        p={2} >
          <Text fontSize={'lg'} textAlign="justify" >
          Pure Randomness creates artworks based on community social coding, but it changes color palettes, shapes, angles etc. by adding random parameters into the algorithm to create more unexpected color combinations, shapes and patterns. All artworks are free claim as your NFT in the Polygon network and transferrable.
          </Text>
        </SimpleGrid>
        <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(4, 1fr)',
        }}
        gap={6}>
        {nftListAll.reverse().map((nft: any, index: number) => (
          <GridItem w='100%'
              key={index}
               gap={2}>
              <Box
                maxW={250}
                bg={'#ffffff'}
                boxShadow={'2xl'}
                overflow={'hidden'}
                m="0 auto"
                h={340}
                className={"photobox"}
                >
                <Image
                  w={'full'}
                  src={nft.image}
                  objectFit={'cover'}
                  h={250}
                />
                <Box p={2}>
                  <Stack spacing={0} align={'center'} mb={2}>
                    <Text fontWeight={'bold'} textAlign={'center'} color={'gray.500'}>{nft.name}</Text>
                  </Stack>
                </Box>
              </Box>

            </GridItem>
          ))}

        </Grid>
      </Box>

      </>
    )
  }

  return (
    <Container maxW={'7xl'} padding={5} className="nftall" overflow="hidden" p={4}>
      {isLoadingAll ? (
        <ProgressCircleTemplate />
      ) : nftListAll.length ? (
        <NFTListTemplate />
      ) : (
        <NoNFTTemplate />
      )}
    </Container>
  )
}

export const NFTSlideshow = () => {
  const address = useAddress()
  const nftCollection = useNFTDrop(NFT_COLLECTION_MUMBAI_CONTRACT)
  const { nftList, setNftList } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!nftList.length) {
      nftCollection?.getOwned(address).then((nftsRes: NFTMetadataOwner[]) => {
        setNftList(nftsRes);
        setIsLoading(false);
      })
    }
  }, []);

  const ProgressCircleTemplate = () => (
    <Flex justifyContent="center" alignItems="center">
      <CircularProgress isIndeterminate color="purple.500" boxSize={200} />
    </Flex>
  )

  const NoNFTTemplate = () => (
    <Flex justifyContent="center" alignItems="center">
      <Text px={8}>You don't have NFT yet, start claiming your first NFT</Text>
    </Flex>
  )

  const NFTListTemplate = () => {
    return (
      <>
      <Box as={Container} maxW={["auto", "auto", "auto", "full",]} mt={5} p={2} colorScheme="white" >
        <Flex>
          <chakra.h2 fontSize="3xl" fontWeight="700" px={2} pb={6}>The NFT You Have Claimed</chakra.h2>
        </Flex>
        <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(4, 1fr)',
        }}
        gap={6}>
        {nftList.slice(-50).reverse().map((nft: any, index: number) => (
          <GridItem w='100%'
              key={index}
               gap={2}>
              <Box
                maxW={250}
                bg={'#ffffff'}
                boxShadow={'2xl'}
                overflow={'hidden'}
                p={2}
                m="0 auto"
                h={340}
                >
                <Image
                  w={'full'}
                  src={nft.metadata.image}
                  objectFit={'cover'}
                  h={250}
                />
                <Box p={2}>
                  <Stack spacing={0} align={'center'} mb={2}>
                  <Text fontWeight={'bold'} textAlign={'center'} color={'gray.500'}>{nft.name}</Text>
                  </Stack>
                </Box>
              </Box>

            </GridItem>
          ))}
          </Grid>
        </Box>
      </>
    )
  }

  return (
    <Container maxW={'7xl'} padding={5} className="nftall" overflow="hidden" p={4}>
      {isLoading ? (
        <ProgressCircleTemplate />
      ) : nftList.length ? (
        <NFTListTemplate />
      ) : (
        <NoNFTTemplate />
      )}
    </Container>
  )
}

export default {NFTAll, NFTSlideshow}
