import {
  Box,
  Text,SimpleGrid, Flex, CircularProgress, Container,Grid, GridItem,
} from "@chakra-ui/react"
import React, { useState, useContext, useLayoutEffect, useEffect } from 'react';
import { MdAccountBalanceWallet } from 'react-icons/md'
import { NFTMetadataOwner } from '@thirdweb-dev/sdk'
import { useMetamask, useAddress, useNFTDrop } from '@thirdweb-dev/react'
import { NFT_COLLECTION_MUMBAI_CONTRACT } from '../config/contracts'
import { AppContext } from '../App'

const year=new Date().getFullYear();
export const Footer = () => {
  return (
    <Container className="logo-bg" id="home" maxW={["auto", "auto", "auto", "7xl"]}>
    <Grid
    templateColumns={{
      base: 'repeat(1, 1fr)',
      sm: 'repeat(1, 1fr)',
      md: 'repeat(1, 1fr)',
      lg: 'repeat(1, 1fr)',
    }}
    gap={6}>
      <GridItem w='100%'
          key="logo"
           gap={2}>
           <Box mt={2} mb={2} >
             <Text fontSize={'sm'} maxW={["auto", "auto", "auto", "1200px",]} p={4} textAlign="center">
               © {year} Pure Randomness. All rights reserved
             </Text>

           </Box>
      </GridItem>
    </Grid>
    </Container>
  )
}

export default {Footer};
