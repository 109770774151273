import * as React from "react"
import {
  ImageProps,
  forwardRef,Text,
  Spacer,Container,Grid, GridItem,
  Flex,Breadcrumb,BreadcrumbItem,BreadcrumbLink,
} from "@chakra-ui/react"

const Logo = forwardRef<ImageProps, "img">((props, ref) => {

  return (
    <Container className="logo-bg" id="home" maxW={["auto", "auto", "auto", "7xl"]}>
    <Grid
    templateColumns={{
      base: 'repeat(1, 1fr)',
      sm: 'repeat(2, 1fr)',
      md: 'repeat(2, 1fr)',
      lg: 'repeat(2, 1fr)',
    }}
    gap={6}>
    <GridItem w='100%'
        key="logo"
         gap={2}>
         <Text fontSize="4xl" fontWeight="bold"  p={[6,6,6,12]}  >
           PURE RANDOMNESS
         </Text>
    </GridItem>
    <GridItem w='100%'
        key="menu"
         gap={2}>
         <Breadcrumb p={[6,6,6,12]}>
           <BreadcrumbItem isCurrentPage>
             <BreadcrumbLink href='#'>Home</BreadcrumbLink>
           </BreadcrumbItem>

           <BreadcrumbItem >
             <BreadcrumbLink href='#description'>Description</BreadcrumbLink>
           </BreadcrumbItem>

           <BreadcrumbItem>
             <BreadcrumbLink href='#recent'>Recently Released</BreadcrumbLink>
           </BreadcrumbItem>

           <BreadcrumbItem >
             <BreadcrumbLink href='#howtoclaim'>Claim an NFT</BreadcrumbLink>
           </BreadcrumbItem>

         </Breadcrumb>
    </GridItem>
    </Grid>
    </Container>
  );
})

export default Logo
